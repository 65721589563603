// global styles


  body {
    height: 100%;
    background-color: #f1f3fa !important;
  }

  img {
    width: 100%;
  }

  hr {
    border: #eee solid 0.5px;
  }

  #root {
    height: 100%;
  }

  .help-text{
    font-size: 12px;
    color: #cacaca
  }

  .input-title{
    display: inline-block;
    padding-bottom: 5px;
  }

  .ant-menu-horizontal {
    line-height: 64px !important;
  }
  .page-header {
    background-color: #fff !important;
    margin-bottom: 20px !important;
  }
  .icon-btn {
    padding: 0px 10px !important;
  }
  .ant-card-head-title{
    font-size: 20px !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45) !important;
  }
