.Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 15px;
}
.Logo {
    width: 330px;
    margin: 0 auto;
    margin-bottom: 15px;
}

.SubLogo {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 15px;
}

.Powered {
    display: block;
    text-align: center;
    margin-bottom: 15px;
}

.SignInForm{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    max-width: 590px;
    margin: 0 auto;
    background-color: #fff;
}
.Text {
    text-align: center;
    margin-bottom: 20px;
}
hr {
    width: 100%;
    margin: 0;
}

.Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 10px 20px;
    // background-color: #f7f8fa;
}