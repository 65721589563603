.Wrapper {
  display: flex;
//   justify-content: center;
//   padding: 0 20px;
}

.WrapperHotspots {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none;
}

.Images {
  position: relative;
  margin-bottom: 10px;
}

.Actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
}

.ImagesWrapp {
  width: 250px;
  // height: 600px;
  overflow-y: scroll;
  background-color: #f7f8fa;
}

.Extra {
  width: 40vw;
  display: flex;
  justify-content: space-between;
}

.ImgCover {
  position: relative;
  overflow: hidden;
  height: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
      width: initial;
      max-width: 100%;
      max-height: 100%;
  }
}

.FileNameWrapp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  padding: 20px 35px 0 20px;
  background: rgba(0, 0, 0, 0.41);
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
}

.FileName {
  position: absolute;
  bottom: 5px;
  font-size: 10px;
  left: 5px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 94%;
}

.Zoom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 250px;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    left: 15px;
    bottom: 15px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    opacity: .5;
    &:hover {
        opacity: 1;
    }
}
