.Logo {
  width: 238px;
  line-height: 20px;
  font-size: 12px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
  margin: 0 auto;
  padding: 0 15px;
  background: #000;
  height: 64px;
  line-height: 64px;
}

.Main {
  display: flex; 
  max-width: 1200px; 
  margin: 25px auto;
}

