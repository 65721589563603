.Upload {
  width: 156px !important;
  margin: 5px;
  height: 120px;
  @media (max-width: 410px) {
    width: 100% !important;
    height: 85px;
  }
  > div {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }
}

.PhotoWrapper {
  position: relative;
  width: 156px;
  margin: 5px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 410px) {
    width: 100%;
    height: 150px;
  }
  > div {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    div {
      display: flex;
    }
  }
}

.UploadDocuments {
  width: 270px !important;
  height: 275px;
  @media (max-width: 600px) {
    width: 100% !important;
  }
  > div {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }
}

.CarouselModal {
  top: 20px;
  width: 100vw !important;
  height: calc(100vh - 50px) !important;
  @media (max-width: 687px) {
    height: calc(100vh / 2) !important;
    width: 100% !important;
    margin: 0 !important;
  }
  @media (max-width: 480px) {
    height: calc(100vh / 2) !important;
  }
}

.CarouselButtonsStyle {
  display: flex;
  margin: 0 12px;
  z-index: 100;

  background-color: red;
  width: 40px;
  height: 100%;
}

.CarouselImage {
  height: calc(100vh - 95px) !important;
  width: auto !important;
  margin: auto;
  @media (max-width: 687px) {
    height: calc(100vh / 2.3) !important;
    width: 100% !important;
  }
  @media (max-width: 480px) {
    height: 250px !important;
  }
  @media (max-width: 400px) {
    height: 200px !important;
  }
}

.VideoCardCover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.MediaBox {
  margin-bottom: 20px;
  :global {
    .ant-card-head-wrapper {
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      > :first-child {
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      > :last-child {
        @media (max-width: 600px) {
          float: left;
          width: 100%;
          overflow-x: scroll;
        }
        button {
          @media (max-width: 600px) {
            margin: 0 10px 0 0 !important;
          }
        }
      }
    }
  }
}
.MediaItemWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 10px;
  @media (max-width: 880px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.MediaItemCard {
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 600px) {
    width: 100% !important;
  }
}

.LinkItem {
  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  :global {
    .ant-list-item-meta {
      a {
        white-space: nowrap;
      }
      @media (max-width: 820px) {
        width: 100%;
        margin-bottom: 5px;
        a {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 210px;
          display: block;
        }
      }
      @media (max-width: 520px) {
        overflow-x: hidden;
      }
    }
    .contentWrapper {
      width: 60%;
      height: 48px;
      @media (max-width: 820px) {
        width: 100%;
      }
      .content {
        width: 100%;
        display: flex;
        @media (max-width: 820px) {
          width: 100%;
        }
        @media (max-width: 520px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        > :first-child {
          @media (max-width: 520px) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.AddCustomVideoModal {
  width: 800px !important;
  @media (max-width: 860px) {
    width: 100% !important;
  }
}

.MatterportDeleteIcon {
  position: absolute;
  right: 155px;
  top: 35px;
  @media (max-width: 860px) {
    right: 0;
    top: 10px;
  }
}
