@media (max-width: 480px) { .hidden-xs { display: none !important; } }
@media (max-width: 576px) { .hidden-sm { display: none !important; } }
@media (max-width: 768px) { .hidden-md { display: none !important; } }
@media (max-width: 992px) { .hidden-lg { display: none !important; } }
@media (max-width: 1200px) { .hidden-xl { display: none !important; } }

@media (max-width: 576px) { .visible-sm { display: block !important; } }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wrapper {
  max-width: 1224px;
  padding: 0 15px;
  margin: 0 auto !important;
}

.grid-wrapper {
  max-width: 85%;
  @media (max-width: 2000px) {
    max-width: 2000px;
  }
}

.page-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.btn {
  text-align: center;
  padding: 4px 0;
  cursor: pointer;
}

.btn-type-border {
  width: 135px;
  border: 1px solid #fa8c16;
  box-sizing: border-box;
  border-radius: 2px;
  color: #fa8c16;
}

.btn-type-full {
  width: 135px;
  background: #f9921a;
  border-radius: 2px;
  color: #fff;
}

.page-header {
  max-width: 1224px;
  margin: 0 auto !important;
  margin-bottom: 0px !important;
}
.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 30px 50px;
  height: calc(100vh - 200px);
}

.footer {
  z-index: 99;
  position: sticky;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-top: 15px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.footer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 15px 5px;
}

.content-wrapper {
  max-width: 1224px;
  margin: 0 auto;
  padding: 25px 15px 0 15px;
  display: flex;
  @media (max-width: 1160px) {
    flex-direction: column;
    width: 100%;
  }
}

.card-wrapper {
  width: 75%;
  @media (max-width: 1160px) {
    width: 100%;
  }
}

.menu-wrapper {
  width: 25%;
  margin-right: 20px;
}

.global-drag-line{
  border-color: #F26723!important;
  border-style: solid!important;
  border-width: 1px!important;
}

.list-item{
  user-select: none;
}