.Wrapper {
    padding-top: 40px;
}

.Logo {
    width: 330px;
    margin-bottom: 10px;
}

.Description {
    text-align: center;
    margin-bottom: 10px;
}

.Header {
    display: flex;
    justify-self: center;
    flex-direction: column;
    align-items: center;
}
 .Steps {
    padding: 10px 50px 40px 50px;
    @media (max-width: 480px) {
        display: none !important;
    }
 }

.InputsWrapper {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 2px;
    padding: 25px;
}