.Footer {
    width: 100%;
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 15px 5px;
    @media (max-width: 580px) {
        justify-content: center;
    }
    @media (max-width: 460px) {
        flex-direction: column;
    }
}

.FooterButton{
    @media (max-width: 460px) {
        width: 100%;
        margin: 3px 0 !important;
    }
}

.Item {
    color: rgba(0, 0, 0, 0.65);
    text-align: right;
}

.NumberInput{
    :global {
        .ant-input-number-handler-wrap {
            display: none;
            visibility: hidden;
        }
    }
}

.RangePriceWrap{
    margin-bottom: 0;
    :global {
        .ant-form-item-control-input-content{
            display: flex;
        }
    }
}

.MenuWrapper{
    width: 25%;
    margin-right: 20px;
    @media (max-width: 1160px) {
        width: 100%;
        margin-right: 0;
    }
    @media (max-width: 680px) {
        display: none;
    }
}

.NewAgentButtonWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}

.NewAgentButton{
    @media (max-width: 680px) {
        width: 100%;
    }
}

.MapWrapper{
    width: 360px;
    height: 200px;
    @media (max-width: 480px) {
        width: auto;
    }
}

.Garage{
    display: flex;
    justify-content: space-between;
    @media (max-width: 860px) {
        flex-direction: column;
    }
}

.EditMapButton{
    margin-top: 15px;
    @media (max-width: 1080px) {
        display: none;
    }
}